<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <b-img src="logoWac.png" style="width: 300px" alt="logos" />
        </b-link>

        <!-- <b-card-title class="mb-1">
          Welcome to Vuexy! 👋
        </b-card-title>
        <b-card-text class="mb-2">
          Please sign-in to your account and start the adventure
        </b-card-text> -->

        <!-- form -->
        <validation-observer ref="loginForm">
          <b-form class="auth-login-form mt-2" @submit.prevent>
            <!-- email -->
            <b-form-group label-for="Username" label="ชื่อผู้ใช้งาน">
              <validation-provider #default="{ errors }" name="username" rules="required">
                <b-form-input
                  id="username"
                  v-model="username"
                  name="username"
                  :state="errors.length > 0 ? false : null"
                  placeholder="ชื่อผู้ใช้งาน"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group label="รหัสผ่าน">
              <validation-provider #default="{ errors }" name="Password" rules="required">
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="login-password"
                    placeholder="รหัสผ่าน"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button variant="primary" type="submit" @click="loginuser" block> ล็อกอิน </b-button>
          </b-form>
        </validation-observer>

        <!-- <b-card-text class="text-center mt-2">
          <span>New on our platform?</span>
          <b-link :to="{ name: 'auth-register-v1' }">
            <span>Create an account</span>
          </b-link>
        </b-card-text>  -->

        <!-- <div class="divider my-2">
          <div class="divider-text">
            or
          </div>
        </div>  -->

        <!-- social button -->
        <!-- <div class="auth-footer-btn d-flex justify-content-center">
          <b-button href="javascript:void(0)" variant="facebook">
            <feather-icon icon="FacebookIcon" />
          </b-button>
          <b-button href="javascript:void(0)" variant="twitter">
            <feather-icon icon="TwitterIcon" />
          </b-button>
          <b-button href="javascript:void(0)" variant="google">
            <feather-icon icon="MailIcon" />
          </b-button>
          <b-button href="javascript:void(0)" variant="github">
            <feather-icon icon="GithubIcon" />
          </b-button>
        </div>  -->
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
// import conn from './conn/connect.js'
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
} from 'bootstrap-vue';
import VuexyLogo from '@core/layouts/components/Logo.vue';
import { required, email } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
// import { password } from '@/@core/utils/validations/validations'
// import conn from './conn/connect.js'
import axios from 'axios';
import API from '@/views/connectDB/condb.js';
import _ from 'lodash';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      username: '',
      password: '',
      status: '',
      // validation rules
      required,
      email,
    };
  },
  async created() {
    this.logout();
    localStorage.removeItem('username');
    localStorage.removeItem('storedData');
    localStorage.removeItem('username_type');
    const urlParams_URL = new URLSearchParams(window.location.search);

    let username_URL = urlParams_URL.get('username');
    console.log(username_URL);
    if (_.isEmpty(localStorage.getItem('storedData'))) {
      const response = await axios.post(`${API}login`, {
        username: 'admin',
        password: 'admin',
      });
      // console.log(response?.data?.message?.refresh_token);
      !!!_.isEmpty(response?.data?.message?.refresh_token) &&
        (localStorage.setItem('username', JSON.stringify(username_URL)),
        localStorage.setItem('storedData', JSON.stringify(response.data.message.refresh_token)));

      //this.$router.push({ name: 'home' });
    }
    const { data } = await axios.post(`${API}permit`, '', {
      headers: {
        Authorization: localStorage.getItem('storedData') && JSON.parse(localStorage.getItem('storedData')),
      },
    });
    const urlParams = new URLSearchParams(window.location.search);
    let token = urlParams.get('token');
    let username = urlParams.get('username');
    const url = `${API}checkHash`;
    const head = {
      headers: {
        Authorization: await data.message.access_token,
      },
    };

    const res = await axios.post(
      url,
      {
        hash: token,
        username: urlParams.get('username'),
      },
      head
    );

    if (res.data.message === 'succeed') {
      const url = `${API}allUsers?username=${username}`;
      const res_user = await axios.get(url, head);

      localStorage.setItem('username_type', JSON.stringify(res_user.data.message.data[0]));
      this.$router.push({ name: 'home' });
      //this.logindata();
    }
  },
  computed: {
    example: {
      cache: false,
      get: function () {
        return Date.now() + this.msg;
      },
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
  },
  methods: {
    async loginuser() {
      const response = await axios.post(`${API}login`, {
        username: this.username,
        password: this.password,
      });
      console.log(response);
      if (response.data.message === 'Username or Password not found.') {
        console.log('sss');
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            text: 'ชื่อผู้ใช้งาน หรือ รหัสผ่าน ไม่ถูกต้อง',
            variant: 'danger',
          },
        });
        return;
      }

      const { data } = await axios.post(`${API}permit`, '', {
        headers: {
          Authorization: localStorage.getItem('storedData') && JSON.parse(localStorage.getItem('storedData')),
        },
      });
      console.log(data.status);

      if (data.status === 200) {
        const head = {
          headers: {
            Authorization: await data.message.access_token,
          },
        };
       
        const url = `${API}allUsers?username=${this.username}`;
        const res_user = await axios.get(url, head);

        localStorage.setItem('username_type', JSON.stringify(res_user.data.message.data[0]));
        this.$router.push({ name: 'home' });
      }
    },
    logout() {
      const urlParams_URL = new URLSearchParams(window.location.search);
      let username_URL = urlParams_URL.get('username');
      console.log(username_URL);

      if (username_URL === 'logout') {
        localStorage.removeItem('username');
        localStorage.removeItem('storedData');
        localStorage.removeItem('username_type');
        console.log('ออกจากระบบ');
      }
    },
    async logindata() {
      // console.log('--------1---------');
      // localStorage.removeItem('username');
      // localStorage.removeItem('storedData');
      // if (_.isEmpty(localStorage.getItem('storedData'))) {
      //   const response = await axios.post(`${API}login`, {
      //     username: 'admin',
      //     password: 'admin',
      //   });
      //   console.log(process.env);
      //   console.log(response?.data?.message?.refresh_token);
      //   !!!_.isEmpty(response?.data?.message?.refresh_token) &&
      //     (localStorage.setItem('username', JSON.stringify(this.username)),
      //     localStorage.setItem(
      //       'storedData',
      //       JSON.stringify(response.data.message.refresh_token)
      //     ));
      //   this.$router.push({ name: 'home' });
      // }
      // const { data } = await axios.post(`${API}permit`, '', {
      //   headers: {
      //     Authorization:
      //       localStorage.getItem('storedData') &&
      //       JSON.parse(localStorage.getItem('storedData')),
      //   },
      // });
      //       const urlParams = new URLSearchParams(window.location.search);
      //       let token = urlParams.get('token');
      //       let username = urlParams.get('username');
      // console.log('--------2---------')
      //       if (!!!_.isEmpty(username) && !!!_.isEmpty(token)) {
      //         const url = `${API}checkHash`;
      //         const head = {
      //           headers: {
      //             Authorization: await data.message.access_token,
      //           },
      //         };
      //         const res = await axios.post(
      //           url,
      //           {
      //             hash: token,
      //             username: urlParams.get('username'),
      //           },
      //           head
      //         );
      //         console.log('--------3---------')
      //         if (res.data.message === 'succeed') {
      //           const { data } = await axios.post(`${API}permit`, '', {
      //             headers: {
      //               Authorization:
      //                 localStorage.getItem('storedData') &&
      //                 JSON.parse(localStorage.getItem('storedData')),
      //             },
      //           });
      //           console.log('--------4---------')
      //           const url = `${API}allUsers?username=${username}`;
      //           const head = {
      //             headers: {
      //               Authorization: await data.message.access_token,
      //             },
      //           };
      //           console.log('--------5---------')
      //           const res_s = await axios.get(url, head);
      //           this.$router.push({ name: 'home' });
      //           // Swal.fire({
      //           //   position: 'center',
      //           //   icon: 'success',
      //           //   title: `${'เข้าสู่ระบบสำเร็จ'}`,
      //           //   showConfirmButton: false,
      //           //   timer: 1500,
      //           // });
      //           //this.$router.push({ name: 'home' });
      //         } else {
      //           console.log('ไม่มีข้อมูลผู้ใช้');
      //           console.log(username);
      //           Swal.fire({
      //             position: 'center',
      //             icon: 'error',
      //             title: res.data.message,
      //             showConfirmButton: false,
      //             timer: 1500,
      //           });
      //         }
      //       }
    },
    async access_token() {
      return (
        await axios.post(`${API}permit`, '', {
          headers: {
            Authorization: localStorage.getItem('storedData') && JSON.parse(localStorage.getItem('storedData')),
          },
        })
      ).data.message;
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
